<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_moduli">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Codice</th>
                                        <th class='all'>Nome</th>
                                        <th class='all'>Descrizione</th>
                                        <th class='all'>Ore</th>
                                        <th class='desktop' style='text-align:center'>Posizione</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="7">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contcodici">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetCodici" class="vcodicisheet" style="z-index: 9999;">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetCodici" fixed style="position:fixed;">
                                Codici
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetCodici = !sheetCodici">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchCodici">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredCodici" :key="item.id"
                                        @click="manageClickCodici(item)">

                                        <v-img alt="Codici Logo" class="  " contain
                                            src="@/assets/filter-code-smartform.png" max-width="24" max-height="24"
                                            transition="scale-transition" />

                                        <v-list-item-title class='itemCodice'>{{ item.codice }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contnomi">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetNomi" class="vnomisheet" style="z-index: 9999;">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetNomi" fixed style="position:fixed;">
                                Nomi
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetNomi = !sheetNomi">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchNomi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredNomi" :key="item.id"
                                        @click="manageClickNomi(item)">


                                        <v-img alt="Codici Logo" class="  " contain
                                            src="@/assets/filter-name-smartform.png" max-width="24" max-height="24"
                                            transition="scale-transition" />

                                        <v-list-item-title class='itemNome'>{{ item.nome }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="280">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Filtri

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <v-text-field v-model="textSearch" label="Cerca testo">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <div id="cont_filtercodice" style="cursor:pointer">
                                <ion-item id="itemCustomer"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Codici </ion-label>

                                    <ion-select id="filercodice" name="filercodice" class='filercodice'
                                        cancel-Text="Chiudi" done-Text="" :value="filterCodice" interface="action-sheet"
                                        disabled readonly>

                                        <ion-select-option v-for="item in codici" :key="item.id" :value='item.id'> {{
                                            item.codice }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <div id="cont_filternome" style="cursor:pointer">
                                <ion-item id="itemCustomer"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Nomi </ion-label>

                                    <ion-select id="filernome" name="filernome" class='filernome' cancel-Text="Chiudi"
                                        done-Text="" :value="filterNome" interface="action-sheet" disabled readonly>

                                        <ion-select-option v-for="item in nomi" :key="item.id" :value='item.id'> {{
                                            item.nome }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/reset-smartform.png"
                                    max-width="35" max-height="35"
                                    style="cursor:pointer;color:black;margin-right:10px;margin-top: -2px;"
                                    transition="scale-transition" title="Elimina Filtri" @click="btnResetSearch" />


                                <v-img alt="" class="btn-confirm-search" contain
                                    src="@/assets/btn_confirm_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupupdpos" style="display:none">

            <template>
                <modal name="popupUpdPos" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Aggiorna Posizione Elementi Modulo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="d_posizione"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute">Posizione</label>

                                            <vue-autonumeric v-model="valuePosizione"
                                                :options="'integer'"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upd-pos" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelUpdPos" />

                                <v-img alt="" class="btn-confirm-upd-pos" contain
                                    src="@/assets/btn_confirm_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmUpdPos" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




    </div>
</template>

<script>
import apimoduli from "../utils/moduli/apimoduli";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";
import router from ".././router";
/* import $ from 'jquery'; */

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        this.isReadOnly = this.$root.$children[0].getValueReadOnly("moduli_smartform_ro");

        console.log("IS RO: ", this.isReadOnly);


        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        window.curSrcText = "";


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '#cont_filternome', function () {

                console.log(("EVENTO CLICK SELCODICE"));

                pointerVue.sheetNomi = true;

            });


            $('body').on('click', '#cont_filtercodice', function () {

                console.log(("EVENTO CLICK SELCODICE"));

                pointerVue.sheetCodici = true;

            });


            $('body').on('click', '.selfiltri', function () {

                console.log(("EVENTO CLICK SELFILTRI"));

                $(".contpopupsearch").show();

                pointerVue.$modal.show('popupSearch');

            });



            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {

            //this.syncProfile();

            this.loadFilters();

        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;

            var ids = "";

            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editModulo/0"
                    });
                    break;

                case "btn_edit":



                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editModulo/" + ids
                    });

                    break;

                case "btn_back":
                    router.push({
                        path: "/filterusers"
                    });
                    break;

                case "btn_copy":


                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da modifcare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        router.push({
                            path: "/copyModulo/" + window.id_par_sel
                        });

                    }

                    break;


                case "btn_update_position":

                    this.valuePosizione = null;

                    $(".contpopupupdpos").show();

                    this.$modal.show('popupUpdPos');

                    break;



                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        pointerVue.updateTotal();

        this.hideShowButtons(false);

    },

    components: {
        VueAutonumeric,
    },



    data: () => ({

        filterNome: "",

        filterCodice: "",

        valuePosizione: null,

        isReadOnly: 0,

        textSearch: "",


        sheetCodici: false,
        searchCodici: '',

        sheetNomi: false,
        searchNomi: '',

        codici: [],
        nomi: [],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {


        filteredCodici() {


            return _.orderBy(this.codici.filter(item => {
                if (!this.searchCodici) return this.codici;
                return (item.codice.toLowerCase().includes(this.searchCodici.toLowerCase()));
            }), 'headline')
        },

        filteredNomi() {


            return _.orderBy(this.nomi.filter(item => {
                if (!this.searchNomi) return this.nomi;
                return (item.nome.toLowerCase().includes(this.searchNomi.toLowerCase()));
            }), 'headline')
        },

    },

    methods: {

        getUrlDomainGo: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = "http://localhost:4013/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = "https://filestransfer.it:4013/";

            }

            return result + nome_metodo;

        },


        btnCancelUpdPos: function () {

            this.$modal.hide('popupUpdPos');


        },

        btnConfirmUpdPos: function () {


            var errore = "";

            console.log("VAL POS: ", this.valuePosizione);

            if (this.valuePosizione == null) {
                errore = "Specificare un valore della posizione";
            }

            if (errore == "") {
                if (this.valuePosizione == "") {
                    errore = "Specificare un valore della posizione maggiore di 0";
                }
            }

            if (errore == "") {

                console.log("ok");

                this.updatePosition();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }




        },





        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;


                }

            });

            console.log("TOT SEL: ", tot);

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push(

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                            //class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Aggiungi"


                        }

                    );


                }



                if (tot == 1) {


                    pulsantis.push(

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            width: 30,
                            title: "Modifica"


                        }

                    );

                    pulsantis.push(

                        {
                            text: "Copia",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/copia_smartform.png",
                            link: "/info",
                            id: "btn_copy",
                            disabled: false,
                            width: 30,
                            title: "Copia"

                        }

                    );


                    pulsantis.push(

                        {
                            text: "Copia",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/update_position_smartform.png",
                            link: "/info",
                            id: "btn_update_position",
                            disabled: false,
                            width: 25,
                            title: "Aggiorna Posizione Elementi Modulo"

                        }

                    );




                }

                if (tot > 0) {


                    pulsantis.push(

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                            //class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Elimina"


                        }

                    );




                }






                this.$root.$children[0].bnavbuttons = pulsantis;


                setTimeout(() => {



                    if (this.isReadOnly == 1) {


                        $('#btn_add').prop('disabled', true)
                        $('#btn_add img').css("opacity", "0.5");

                        $('#btn_edit').prop('disabled', true)
                        $('#btn_edit img').css("opacity", "0.5");

                        $('#btn_copy').prop('disabled', true)
                        $('#btn_copy img').css("opacity", "0.5");

                        $('#btn_delete').prop('disabled', true)
                        $('#btn_delete img').css("opacity", "0.5");

                        $('#btn_update_position').prop('disabled', true)
                        $('#btn_update_position img').css("opacity", "0.5");

                    }



                }, 200);



            }, 100);



        },


        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearchOld: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },

        btnResetSearch: function () {


            window.curSrcText = "";
            this.textSearch = "";

            window.curCodice = "";
            this.filterCodice = "";

            window.curNome = "";
            this.filterNome = "";

            $('.selfiltri').attr('src', '/smartform/img/smartform-filtri-disattivi.png');


            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);
        },

        btnConfirmSearch: function () {


            window.curSrcText = this.textSearch;

            window.curCodice = this.filterCodice;

            window.curNome = this.filterNome;

            $('.selfiltri').attr('src', '/smartform/img/smartform-filtri-attivi.png');

            var filter1 = 1;
            if (this.textSearch == "") {
                filter1 = 0;
            }

            var filter2 = 1;
            if (this.filterCodice == "") {
                filter2 = 0;
            }

            var filter3 = 1;
            if (this.filterNome == "") {
                filter3 = 0;
            }

            if ((filter1 == 0) && (filter2 == 0) && (filter3 == 0)) {

                $('.selfiltri').attr('src', '/smartform/img/smartform-filtri-disattivi.png');
            }

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },

        updateTotal: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log("CUR_CODICE: ", window.curCodice);
            console.log("CUR_NOME: ", window.curNome);

            that.$root.$children[0].showProgress = true;

            var response = await apimoduli.getTotalHour(
                window.curCodice,
                window.curNome,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTotalHour", res);

                $("#val_tot_ore").html(res.data.OreTotali);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        loadFilters: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apimoduli.getFilters(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getFilters", res);

                console.log("CODICI: ", res.data.Codici);

                this.codici = res.data.Codici;

                this.nomi = res.data.Nomi;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        loadNomiByCodice: async function (id_codice) {


            var that = this;

            var v_token = window.$cookies.get("token");

            that.$root.$children[0].showProgress = true;

            var response = await apimoduli.getNomiByCodice(
                id_codice,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getNomiByCodice", res);


                this.nomi = res.data.Nomi;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        manageClickCodiciOld: async function (codice) {

            console.log("CODE SEL: ", codice);

            this.sheetCodici = false;

            window.curCodice = codice.id;

            this.hideShowButtons(false);

            this.loadNomiByCodice(codice.id);

            this.updateTotal();

            window.table.ajax.reload();


        },

        manageClickCodici: async function (codice) {

            console.log("CODE SEL: ", codice);

            this.sheetCodici = false;

            this.filterCodice = codice.id;



        },

        manageClickNomiOld: async function (nome) {

            console.log("NOME SEL: ", nome);

            this.sheetNomi = false;

            window.curNome = nome.id;

            this.hideShowButtons(false);

            this.updateTotal();

            window.table.ajax.reload();


        },

        manageClickNomi: async function (nome) {

            console.log("NOME SEL: ", nome);

            this.sheetNomi = false;

            this.filterNome = nome.id;


        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;

            //var dateTest = new Date();
            //dateTest = dateTest.setDate(dateTest.getDate() + 365);
            //const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            // var filtri = `<img src='/smartform/img/filter-code-smartform.png' style='border:0!important;' class='selcodice' title='Codice' /><img src='/smartform/img/filter-name-smartform.png' style='border:0!important;' class='selnome' title='Nome' /><div style='font-weight:bold;margin-left:26px'>Totale Ore:</div><div style='font-weight:bold;margin-left:6px' id='val_tot_ore'>0,00</div>`;

            var filtri = `<img src='/smartform/img/smartform-filtri-disattivi.png' style='border:0!important;' class='selfiltri' title='Filtri' /><div style='font-weight:bold;margin-left:26px'>Totale Ore:</div><div style='font-weight:bold;margin-left:6px' id='val_tot_ore'>0,00</div>`;


            const date = new Date().toJSON().slice(0, 10)

            window.curCodice = "-1";
            window.curNome = "-1";


            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                //  var v_company = window.$cookies.get("sel_filter_azienda_utenti");

                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'codice',
                    orderable: false,
                    name: 'codice',
                    width: '1%'
                },
                {
                    data: 'nome',
                    orderable: false,
                    name: 'nome',
                    width: '6%'
                },

                {
                    data: 'descrizione',
                    orderable: false,
                    name: 'descrizione',
                    width: '6%',
                },

                {
                    data: 'ore_totali',
                    orderable: false,
                    name: 'ore_totali',
                    width: '2%',
                },

                {
                    data: 'position',
                    orderable: false,
                    name: 'position',
                    width: '2%',
                },

                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",


                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomainGo("getModuli") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curCodice = window.curCodice;
                            valori.curNome = window.curNome;


                            valori.curSrcText = window.curSrcText;


                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });


                        setTimeout(function () {


                            pointerVue.$root.$children[0].showProgress = false;


                        }, 700);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');

                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomainGo("getModuli") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Moduli</div>
                    ` + filtri + `

                </div>
                

            `);


                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });



                $('body').on('click', '.selcodice', function () {

                    console.log(("EVENTO CLICK SELCODICE"));

                    pointerVue.sheetCodici = true;

                });

                $('body').on('click', '.selnome', function () {

                    console.log(("EVENTO CLICK SELNOME"));

                    pointerVue.sheetNomi = true;

                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });

                $('body').on('click', '.cont_moduli tr', function () {

                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }


                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Aggiungi"

                        },


                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Aggiungi"

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            width: 30,
                            title: "Modifica"

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                            //class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Elimina"

                        },

                        {
                            text: "Copia",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/copia_smartform.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_copy",
                            disabled: false,
                            width: 30,
                            title: "Copia"
                        },


                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;



                setTimeout(() => {



                    if (this.isReadOnly == 1) {


                        $('#btn_add').prop('disabled', true)
                        $('#btn_add img').css("opacity", "0.5");

                    }



                }, 200);






            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                        // class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30,
                        title: "Aggiungi"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                        //class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Modifica"

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30,
                        title: "Elimina"

                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },



        updatePosition: async function () {

            this.$modal.hide('popupUpdPos');

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimoduli.updatePosition(
                ids,
                that.valuePosizione
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updatePosition", res);

                that.$swal({
                    icon: "success",
                    text: "Posizione aggiornata correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();


                    window.table.ajax.reload();

                    that.hideShowButtons(false);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );







        },










        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;


            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apimoduli.deleteModuloGo(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteModuloGo", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                // window.table._fnDraw();
                                // that.hideShowButtons(false);

                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },







    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont_moduli .selfiltri {
    max-width: 27px;
    min-height: 27px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #00a6ff;

}

.pay a {
    color: #00a6ff;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #00a6ff;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.headerSheetCodici {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetNomi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_moduli #myTable {
    margin-right: 3px;
}

.cont_moduli table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_moduli table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-top: 1px !important;
}

.cont_moduli th {
    padding-left: 5px !important;
}

.cont_moduli ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_moduli .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_moduli #myTable_filter {
    top: 66px;
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #0084f3;
}

.cont_moduli .cl_position {
    text-align: center;
}



.cont_moduli div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_moduli #myTable_wrapper {
    padding-top: 0px;
}

.cont_moduli .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 82vw
}

.cont_moduli .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_moduli .cont_buttons .text_title {
    min-width: 40px;

}

.cont_moduli .text_title {
    min-width: 40px !important;
}

.cont_moduli .cont_buttons {
    min-width: 102px;
}


.selcodice {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.selnome {
    max-width: 23px;
    min-height: 28px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.cont_moduli .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_moduli .closeSheet {
    position: absolute;
    right: 10px;
    /* top: 26px; */

    top: 14px !important;

    z-index: 2;
}


.itemCodice {
    margin-left: 15px;
}

.itemNome {
    margin-left: 15px;
}

.align_close_sheet {
    top: 23px !important
}

.v-application .text-center {

    overflow: auto;
}


.swal2-icon.swal2-warning {
    border-color: #0084f3 !important;
    color: #0084f3 !important;
}


.cont_moduli .val_status {
    width: 10px !important;
}


.cont_moduli .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_moduli .checkall {
    width: 13px;
    height: 13px;
}

@media screen and (max-width: 960px) {

    .cont_moduli .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_moduli #myTable {
        /* margin-top:54px; */
    }

    .cont_moduli #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_moduli .cl_position {
        display: inline-flex;
    }

    .cont_moduli .cl_checkall {
        padding-left: 19px;
        padding-top: 2px;
    }


    .cont_moduli .val_status {
        width: 10px !important;
        margin-top: 7px !important;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
        background-color: #a4a4b0;
        margin-top: -7px;
    }


}

@media screen and (max-width: 1024px) {

    .align_close_sheet {
        top: 18px !important
    }


    .contIcon {
        display: inherit;
    }



    .cont_moduli #myTable {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_moduli #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_moduli .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_moduli #myTable_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_moduli .cl_num {
        margin-left: -2px !important;
    }





}
</style>
