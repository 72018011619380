<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_corsi_online_pesi_risposte_smartform">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:64px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Descrizione</th>
                                        <th class='all' style="text-align: center;">Risposta</th>
                                        <th class='all' style="text-align: center;">Peso</th>



                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="5">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/smartform_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel_smartform.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain
                                    src="@/assets/btn_confirm_smartform.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Corsi
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.id"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/corsi_online_smartform.png" max-width="24"
                                            max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.full_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contdomande">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetDomande" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Domande
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetDomande = !sheetDomande">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchDomande">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredDomande" :key="item.id"
                                        @click="manageClickDomande(item)">

                                        <v-img alt="" src="@/assets/corsi_online_smartform.png" max-width="24"
                                            max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.descrizione
                                        }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>



    </div>
</template>

<script>

import apirisorse from "../utils/risorse/apirisorse";
import apicorsi from "../utils/corsi/apicorsi";
import apiuploadfile from "../utils/uploadfile/apiuploadfile";

import {
    bus
} from "../main";
import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        var pointerVue = this;



        this.isReadOnly = this.$root.$children[0].getValueReadOnly("corsi_smartform_ro");

        console.log("IS RO: ", this.isReadOnly);




        window.curAnno = 2022; // default 2002



        window.curSrcText = "";

        window.curCompany = window.$cookies.get("sel_filter_corsi");

        window.curDomanda = window.$cookies.get("sel_filter_domanda");

        window.NomeCorsoSel = window.$cookies.get("sel_corso_name");

        window.DescDomandaSel = window.$cookies.get("sel_domanda_name");

        window.TipologiaDomandaSel = window.$cookies.get("sel_domanda_tipologia");

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {


            $('body').on('click', '.selanno', function () {

                console.log(("EVENTO CLICK SELCORSO"));

                pointerVue.sheetAnni = true;

            });




            $('body').on('change', '#input-file-logo', function () {

                console.log(("EVENTO CHANGE INPUTFILELOG"));


                pointerVue.editPdfRel = 0;

                pointerVue.editPdfDoc = 0;


            });




            $('body').on('ionChange', '.dateSel', function () {

                console.log(("EVENTO CLICK DATESEL"));

                console.log("VAL DATESEL: ", this.value);
                console.log("DATE CORSO: ", pointerVue.dateCorso);

                var txtDataSel = "";

                for (var x = 0; x < pointerVue.dateCorso.length; x++) {

                    if (parseInt(pointerVue.dateCorso[x].id) == parseInt(this.value)) {
                        txtDataSel = pointerVue.dateCorso[x].data;
                    }

                }

                console.log("TXT DATESEL: ", txtDataSel);



                pointerVue.assocModuliGetArgs(this.value, txtDataSel);




            });








            $('body').on('click', '.selcorso', function () {

                console.log(("EVENTO CLICK SELCORSO"));

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '.seldomanda', function () {

                console.log(("EVENTO CLICK SELDOMANDA"));

                pointerVue.sheetDomande = true;

            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {

            this.syncCorsiDomande();

        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;

            var ids = "";

            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;


                case "btn_filter_corsi":

                    this.sheetAziende = true;

                    setTimeout(() => {

                        if (!this.getWindowMobile()) {

                            $(".v-dialog__content").css("margin-top", "64px");


                        }


                    }, 200);


                    break;

                case "btn_filter_domande":

                    this.sheetDomande = true;

                    setTimeout(() => {

                        if (!this.getWindowMobile()) {

                            $(".v-dialog__content").css("margin-top", "64px");


                        }


                    }, 200);

                    break;



                case "btn_add":
                    router.push({
                        path: "/editPesoRispostaCorsoOnline/0"
                    });
                    break;



                case "btn_edit":

                    ids = "";


                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");

                        }

                    });

                    router.push({
                        path: "/editPesoRispostaCorsoOnline/" + ids
                    });




                    break;





                default:
                    break;

            }
        });


        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        // var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */


        setTimeout(() => {

            pointerVue.setupGrid();

        }, 200);



        this.hideShowButtons(false);

    },
    data: () => ({


        sheetDomande: false,
        searchDomande: "",
        domande: [],
        domandeTemp: [],


        corsi: [],

        // parametri funzione upload
        testoPulsante: "Upload Visura",
        titoloPopup: "Allega Visura Camerale",
        fileAccettati: ".pdf",
        nomeFilePresente: "",
        dimFilePresente: "",
        dimKbFilePresente: 0,
        dimMinKbFile: 100000,
        dimMaxKbFile: 5000000,
        folderServerToSave: "/documents/Agila/Visura/",
        folderDoxToSave: "/CorsiOnline/",
        removeUploadFile: 0,
        attachRemoveVisura: false,




        anni: [{ "id": 2020, "nome": 2020 }, { "id": 2021, "nome": 2021 }, { "id": 2022, "nome": 2022 }, { "id": 2023, "nome": 2023 }],


        sheetAnni: false,

        posAssocModuli: "",

        editPdfRel: 0,
        editPdfDoc: 0,

        viewDeleteRel: false,

        viewDeleteDoc: false,


        disChk: false,


        isReadOnly: 0,

        moduli: [],
        idModuloSel: [],

        nomeArgomento: "",
        codiceArgomento: "",

        idPermSel: [],

        argomenti: [],

        viewRow: false,

        dateSelected: "",
        timeFrom: "",
        timeTo: "",
        totalHours: "",

        totalHoursSelected: "",

        dateCorso: [],
        dateSel: null,



        aziende: [],

        sheetAziende: false,
        search: "",

        textSearch: "",

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.corsi.filter(item => {
                if (!this.search) return this.corsi;
                return (item.full_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

        filteredDomande() {
            return _.orderBy(this.domande.filter(item => {
                if (!this.searchDomande) return this.domande;
                return (item.descrizione.toLowerCase().includes(this.searchDomande.toLowerCase()));
            }), 'headline')


        },

    },



    methods: {

        getUrlDomainGo: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = "http://localhost:4013/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = "https://filestransfer.it:4013/";

            }

            return result + nome_metodo;

        },


        getWindowMobile: function () {

            if (window.innerWidth <= 768) {

                return true;

            }
            else {

                return false;

            }

        },


        setDomande: function (id_corso) {

            console.log("ID CORSO SEL: ", id_corso);

            console.log("DOMANDE TMP: ", this.domandeTemp);

            var Domande = [];

            var count = 1;

            for (var i = 0; i < this.domandeTemp.length; i++) {

                if (parseInt(this.domandeTemp[i].id_corso) == parseInt(id_corso)) {

                    Domande.push({
                        id: this.domandeTemp[i].id,
                        id_corso: this.domandeTemp[i].id_corso,
                        descrizione: count.toString() + ". " + this.domandeTemp[i].descrizione,
                        tipologia: this.domandeTemp[i].tipologia,
                    });

                    count = count + 1;

                }

            }

            console.log("DOMANDE SEL: ", Domande);

            this.domande = Domande;

        },

        btnCancelUpload: function () {

            this.$modal.hide('popupUploadFile');

        },


        btnConfirmUpload: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];

            if (v_file.files.length > 0) {



                // controllo estensione

                v_file = v_file.files[0];

                console.log("FILE 1: ", v_file);

                var v_elements = v_file.name.split('.');

                var v_ext_file = v_elements[v_elements.length - 1];

                console.log("FILE ELEMENTS: ", v_elements);
                console.log("FILE EXT: ", v_ext_file);


                var v_ele_file_accept = this.fileAccettati.split(',');

                console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                var v_find_ext = 0;

                for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                    var v_ext_file_tmp = "." + v_ext_file;

                    console.log(v_ext_file_tmp);

                    if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                        v_find_ext = 1;
                    }

                }

                if (v_find_ext == 0) {
                    errore = "I formati accettati sono " + this.fileAccettati;
                }



                if (errore == "") {

                    // controllo dimensione file

                    if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                        var strMin = (this.dimMinKbFile / 1000) + " Kb";

                        var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                        errore = "La grandezza del file deve essere compresa tra " + strMin + " e " + strMax;

                    }


                }






            }




            if (errore == "") {

                console.log("ok");

                this.uploadFile();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3500
                });


            }


        },


        uploadFile: async function () {

            var that = this;

            var id_rec = "";
            var val_anno = "";
            var desc_cmp = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (id_rec != "") {
                        id_rec = id_rec + ",";
                    }
                    id_rec = id_rec + $(this).attr("data-id");

                    val_anno = $(this).attr("val-anno");
                    desc_cmp = $(this).attr("desc-cmp");


                }

            });

            that.folderServerToSave = "/documents/smartform/" + desc_cmp + "/" + val_anno.toString() + "/CorsiOnline/";


            var v_file = $("#input-file-attach")[0].files[0];

            that.$modal.hide('popupUploadFile');


            console.log("ID_REC: ", id_rec);
            console.log("FILE: ", v_file);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response3 = await apiuploadfile.setFile(
                id_rec,
                v_file,
                that.folderServerToSave,
                that.folderDoxToSave,
                "smartform_corsi_online",
                v_token,
                that.removeUploadFile,
                desc_cmp
            ).then((res2) => {

                console.log(response3);

                that.$root.$children[0].showProgress = false;
                console.log("res from setFile", res2);


                that.$swal({
                    icon: "success",
                    text: "File caricato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });


                setTimeout(() => {

                    window.table.ajax.reload();


                }, 2000);


            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );





        },



        openPopupUpload: async function () {

            var that = this;


            that.nomeFilePresente = "";
            that.dimFilePresente = "";
            that.dimKbFilePresente = 0;

            var id_rec = "";
            var val_anno = "";
            var desc_cmp = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (id_rec != "") {
                        id_rec = id_rec + ",";
                    }
                    id_rec = id_rec + $(this).attr("data-id");

                    val_anno = $(this).attr("val-anno");
                    desc_cmp = $(this).attr("desc-cmp");


                }

            });


            that.folderServerToSave = "/documents/smartform/" + desc_cmp + "/" + val_anno.toString() + "/CorsiOnline/";


            var errore = "";


            if (errore == "") {

                var v_token = window.$cookies.get("token");



                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response3 = await apiuploadfile.getFile(
                    id_rec,
                    "smartform_corsi_online",
                    that.folderServerToSave,
                    v_token
                ).then((res2) => {

                    console.log(response3);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from getFile", res2);


                    that.dimMinKbFile = res2.data.MinFileSize;

                    that.dimMaxKbFile = res2.data.MaxFileSize;


                    $(".contpopupuploadfile").show();

                    this.$modal.show('popupUploadFile');


                    setTimeout(() => {


                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);

                            that.removeUploadFile = 1;

                            that.nomeFilePresente = "";
                            that.dimFilePresente = "";
                            that.dimKbFilePresente = 0;

                        });



                        $('#input-file-attach').on('change', function () {

                            that.removeUploadFile = 0;

                            var v_file = $("#input-file-attach")[0].files[0];

                            console.log("FILE: ", v_file);

                            that.nomeFilePresente = v_file.name;

                            var v_size = v_file.size;

                            that.dimKbFilePresente = v_size;

                            var str_size = "0 Kb";


                            if (v_size > 0) {

                                var v_division = v_size / 1000;

                                str_size = v_division.toFixed(1) + " Kb";


                            }



                            that.dimFilePresente = str_size;



                        });



                        console.log("FILE PRESENT: ", res2.data.NomeFile);

                        if (res2.data.NomeFile != null) {

                            if (res2.data.NomeFile != "") {

                                var elements = res2.data.NomeFile.split(".");

                                console.log("ELEMENTS: ", elements);

                                var v_ext = elements[elements.length - 1];

                                console.log("EXT: ", v_ext);

                                $(".rowPrevFile").find('.dropify-render').append("<i class='dropify-font-file'></i>");
                                $(".rowPrevFile").find('.dropify-render').append("<span class='dropify-extension'>" + v_ext + "</span>");
                                $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                $(".rowPrevFile").find(".dropify-preview").show("slow");



                                that.nomeFilePresente = res2.data.NomeFile;

                                that.dimKbFilePresente = res2.data.FileSize;


                                var str_size = "0 Kb";

                                var v_size = res2.data.FileSize;

                                if (v_size > 0) {

                                    var v_division = v_size / 1000;

                                    str_size = v_division.toFixed(1) + " Kb";


                                }



                                that.dimFilePresente = str_size;

                            }


                        }








                    }, 300);



                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }




        },








        updatePosAssoc: async function (v_pos, v_id) {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("VALORE POS: ", v_pos);
            console.log("ID CORSO: ", ids);
            console.log("ID FIELD: ", v_id);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.setPosModuloAssoc(
                ids,
                v_pos,
                v_id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setPosModuloAssoc", res);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        handleUpdateItem: function (evt) {

            console.log("TARGET: ", evt.target);


            console.log("BLUR: ", evt.target.value);

            this.updatePosAssoc(evt.target.value, evt.target.id);


        },


        filterKeys: function (evt) {

            try {

                evt = (evt) ? evt : window.event;
                let expect = evt.target.value.toString() + evt.key.toString();

                if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                    evt.preventDefault();
                } else {
                    return true;
                }

            } catch (error) {

                console.log(error);

            }




        },


        btnDeleteFileDoc: function () {

            this.deleteDocumento();

        },

        btnDeleteFileRel: function () {

            this.deleteRelazione();

        },

        btnCancelUploadDoc: function () {

            this.$modal.hide('popupUploadDoc');

        },

        btnConfirmUploadDoc: function () {

            var errore = "";

            if (this.editPdfDoc == 0) {

                if ($('#input-file-logo')[0].files.length == 0) {

                    errore = "Allegato richiesto";


                }


            }


            if (errore == "") {

                if (this.editPdfDoc == 0) {

                    if ($('#input-file-logo')[0].files.length > 0) {

                        var v_file = $('#input-file-logo')[0].files[0];

                        var elements = v_file.name.split('.');
                        var extension = elements[elements.length - 1];

                        if (extension.toString().toUpperCase() != "PDF") {

                            errore = "Formati del file supportati PDF";


                        }

                    }

                }


            }



            if (errore == "") {

                console.log("ok");

                this.saveUploadDoc();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },









        btnCancelUploadRel: function () {

            this.$modal.hide('popupUploadPdf');

        },

        btnConfirmUploadRel: function () {

            var errore = "";

            console.log("VAL PDF REL: ", this.editPdfRel);

            if (this.editPdfRel == 0) {

                if ($('#input-file-logo')[0].files.length == 0) {

                    errore = "Allegato richiesto";


                }
            }



            if (errore == "") {

                if (this.editPdfRel == 0) {

                    if ($('#input-file-logo')[0].files.length > 0) {

                        var v_file = $('#input-file-logo')[0].files[0];

                        var elements = v_file.name.split('.');
                        var extension = elements[elements.length - 1];

                        if (extension.toString().toUpperCase() != "PDF") {

                            errore = "Formati del file supportati PDF";


                        }

                    }

                }



            }



            if (errore == "") {

                console.log("ok");

                this.saveUpload();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }


        },


        saveUploadDoc: async function () {

            this.$modal.hide('popupUploadDoc');

            var that = this;

            var v_token = window.$cookies.get("token");
            var id_azienda = window.$cookies.get("sel_filter_corsi");

            var ids = "";

            $(".val_status").each(function (index, val) {

                if ($(val).prop("checked")) {

                    var id = $(val).attr("data-id");

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + id;

                }

            });


            console.log("TOKEN: ", v_token);
            console.log("ID CORSO: ", ids);
            console.log("ID AZIENDA: ", id_azienda);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.uploadAllegatoCorsoDocumento(
                v_token,
                id_azienda,
                ids,
                $('#input-file-logo')[0].files[0]
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from uploadAllegatoCorsoDocumento", res);


                that.$swal({
                    icon: "success",
                    text: "Allegato documento caricato con successo",
                    showConfirmButton: false,
                    timer: 3000
                });


                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },





        saveUpload: async function () {

            this.$modal.hide('popupUploadPdf');

            var that = this;

            var v_token = window.$cookies.get("token");
            var id_azienda = window.$cookies.get("sel_filter_corsi");

            var ids = "";

            $(".val_status").each(function (index, val) {

                console.log(index);

                if ($(val).prop("checked")) {

                    var id = $(val).attr("data-id");

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + id;

                }

            });


            console.log("TOKEN: ", v_token);
            console.log("ID CORSO: ", ids);
            console.log("ID AZIENDA: ", id_azienda);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.uploadAllegatoCorsoOnline(
                v_token,
                id_azienda,
                ids,
                $('#input-file-logo')[0].files[0]
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from uploadAllegatoCorsoOnline", res);


                that.$swal({
                    icon: "success",
                    text: "Allegato caricato con successo",
                    showConfirmButton: false,
                    timer: 3000
                });


                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        btnCancelAssocModuli: function () {

            var that = this;

            this.$modal.hide('popupCorsoModuli');


            setTimeout(() => {

                that.dialogMsg = false;
                that.setupButtons();

                that.hideShowButtons(false);

                window.table.ajax.reload();

            }, 200);


        },

        chkSelMod: async function () {

            // console.log("ID: ", $(this));

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("ID MOD SEL: ", that.idModuloSel);

            console.log("ID CORSO SEL: ", ids);

            var response = await apicorsi.setModulo(
                ids,
                that.idModuloSel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setModulo", res);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },

        chkSelArg: function () {

            var errore = "";


            var that = this;

            console.log("ID SEL TOT: ", that.idPermSel);


            var v_tot_min_sel = 0;


            $(".contCheck input").each(function (index, val) {

                console.log("INDEX: ", index);
                console.log("VAL: ", val);

                var v_id = $(this).val();

                //console.log("ID: ", v_id);

                for (var xx = 0; xx < that.idPermSel.length; xx++) {

                    if (parseInt(v_id) == parseInt(that.idPermSel[xx])) {

                        var v_tot = $(this).attr("data_tot");

                        var v_min_val = that.getMinutesTotal(v_tot, ",");

                        //console.log("MIN SEL: ", v_min_val);


                        v_tot_min_sel = v_tot_min_sel + v_min_val;

                    }

                }


            });

            that.disChk = true;

            $(".btn-cancel-search").hide();

            console.log("MIN TOT: ", v_tot_min_sel);

            if (v_tot_min_sel > 480) // al massimo 8 ore di corso nella giornata
            {

                errore = "Selezionare al massimo 8 ore";

            }


            if (errore == "") {

                var v_tot_sel = parseFloat(that.getHMTotal(v_tot_min_sel));

                console.log("HH MM TOT: ", v_tot_sel);


                that.totalHours = v_tot_sel.toFixed(2).toString().replace(".", ",");

                that.totalHoursSelected = v_tot_sel.toFixed(2).toString().replace(".", ",");

            }


            if (errore == "") {

                console.log("ok");





                setTimeout(() => {

                    that.assicGiorniCorsoSave(v_tot_min_sel, v_tot_sel);

                }, 200);




            }
            else {

                that.disChk = false;

                $(".btn-cancel-search").show();

                console.log("ARRAY ERR 1: ", that.idPermSel);
                var element = that.idPermSel.pop();
                console.log("LAST ELEMENT: ", element);
                console.log("ARRAY ERR 2: ", that.idPermSel);



                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }


        },

        assicGiorniCorsoSave: async function (v_tot_min_sel, v_tot_sel) {

            var that = this;

            console.log("ID DATE SEL: ", $("#dateSel").val());

            console.log("NOME ARG: ", that.nomeArgomento);
            console.log("CODICE ARG: ", that.codiceArgomento);

            var str_id = "";
            for (var xx = 0; xx < that.idPermSel.length; xx++) {
                if (str_id != "") {
                    str_id = str_id + ",";
                }
                str_id = str_id + that.idPermSel[xx].toString();

            }

            console.log("IDS: ", str_id);


            var txtDataSel = "";

            for (var xx2 = 0; xx2 < that.dateCorso.length; xx2++) {

                if (parseInt(that.dateCorso[xx2].id) == parseInt($("#dateSel").val())) {
                    txtDataSel = that.dateCorso[xx2].data;
                }

            }

            console.log("DATE SEL: ", txtDataSel);

            console.log("TOT HH SEL: ", v_tot_sel);

            console.log("TOT MIN SEL: ", v_tot_min_sel);


            // id corso selezionato
            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.assocGiorniSave(
                $("#dateSel").val(),
                that.codiceArgomento,
                that.nomeArgomento,
                str_id,
                txtDataSel,
                v_tot_sel,
                v_tot_min_sel,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from assocGiorniSave", res);


                that.timeFrom = res.data.TimeStart;
                that.timeTo = res.data.TimeEnd;


                that.disChk = false;

                $(".btn-cancel-search").show();

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );





        },


        getHMTotal: function (v_m_tot) {

            var result = "";

            var val_hh = parseInt(v_m_tot / 60);

            var val_mm = v_m_tot % 60;

            if (parseInt(val_mm) < 10) {
                val_mm = "0" + val_mm.toString();
            }

            result = val_hh + "." + val_mm;

            return result;


        },

        getTotal: function () {

            var v_tot_min_sel = 0;

            var that = this;

            $(".contCheck input:checked").each(function (index, val) {

                console.log("INDEX: ", index);
                console.log("VAL: ", val);


                var v_tot = $(this).attr("data_tot");

                var v_min_val = that.getMinutesTotal(v_tot, ",");

                v_tot_min_sel = v_tot_min_sel + v_min_val;

            });

            console.log("MIN TOT: ", v_tot_min_sel);

            var v_tot_sel = parseFloat(that.getHMTotal(v_tot_min_sel));

            console.log("HH MM TOT: ", v_tot_sel);


            that.totalHours = v_tot_sel.toFixed(2).toString().replace(".", ",");

            that.totalHoursSelected = v_tot_sel.toFixed(2).toString().replace(".", ",");


        },


        getMinutesTotal: function (v_h_m, val_split) {


            var result = 0;

            var elements = v_h_m.split(val_split);

            result = (parseInt(elements[0]) * 60) + parseInt(elements[1]);

            return result;


        },

        assocModuliGetArgs: async function (v_id_data, v_date_sel) {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            console.log("ID CORSO: ", ids);
            console.log("ID DATA: ", v_id_data);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.assocGiorniGetArgs(
                v_id_data,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from assocGiorniGetArgs", res);

                console.log("DATE SEL: ", v_date_sel);

                that.dateSelected = v_date_sel;

                that.timeFrom = res.data.ValOreDalle;
                that.timeTo = res.data.ValOreAlle;

                that.argomenti = res.data.ValArgomenti;


                that.idPermSel = [];

                // 238,239,240,241

                // that.idPermSel.push(238);
                // that.idPermSel.push(239);
                // that.idPermSel.push(240);
                // that.idPermSel.push(241);

                if (res.data.IdElemsSel != null) {

                    console.log("ID PERM SEL 1");


                    if (res.data.IdElemsSel.length > 0) {

                        console.log("ID PERM SEL 2");

                        var v_array = res.data.IdElemsSel.split(",");

                        console.log("ARRAY: ", v_array);

                        for (var xsel = 0; xsel < v_array.length; xsel++) {

                            that.idPermSel.push(parseInt(v_array[xsel]));

                        }

                        //that.idPermSel = res.data.IdElemsSel.split(",");


                    }

                }

                that.viewRow = true;



                setTimeout(() => {




                    that.getTotal();




                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );



        },


        btnCancelModuliGiornate: function () {

            this.$modal.hide('popupModuliGiornate');


        },


        assocModuliGiorni: async function () {

            var that = this;

            that.viewRow = false;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.assocGiorniGetDate(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from assocGiorniGetDate", res);

                that.dateCorso = res.data.ValDate;


                that.nomeArgomento = res.data.ValNomeArgomento;

                that.codiceArgomento = res.data.ValCodiceArgomento;

                // that.disChk = true;

                setTimeout(() => {


                    $(".contpopupmoduligiornate").show();

                    this.$modal.show('popupModuliGiornate');

                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );







        },


        reportPdf: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var id_azi = window.$cookies.get("sel_filter_corsi");

            var response = await apicorsi.reportPdf(
                ids,
                id_azi
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from reportPdf", res);



                var file_path = res.data.ValUrl;
                var a = document.createElement('A');
                a.href = file_path;
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        getModuliCorso: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.getModuli(
                v_token,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getModuli", res);

                that.moduli = res.data.Result;

                that.idModuloSel = res.data.StrModAssoc.split(",");


                setTimeout(() => {


                    $(".contpopupcorsomoduli").show();

                    this.$modal.show('popupCorsoModuli');


                    setTimeout(() => {


                        if (res.data.StrPosModAssoc != null) {

                            var v_array = res.data.StrPosModAssoc.split(",");

                            console.log("ARRAY POS: ", v_array);

                            for (var xx = 0; xx < v_array.length; xx++) {

                                if (v_array[xx] != "") {


                                    var v_array2 = v_array[xx].split("|");


                                    $("#" + v_array2[0]).val(v_array2[1]);

                                }






                            }






                        }






                    }, 300);






                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncCorsiDomande: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.getCorsiOnlineDomandeGo(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCorsiOnlineDomandeGo", res);

                that.corsi = res.data.Corsi;
                that.domandeTemp = res.data.Domande;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        manageClickAziende: async function (corso) {

            console.log("CORSO SEL: ", corso);

            this.sheetAziende = false;

            window.curCompany = corso.id;

            window.$cookies.set("sel_filter_corsi", corso.id, "9y");

            var desc_azi_sel = "";

            for (var xx = 0; xx <= this.corsi.length - 1; xx++) {


                if (parseInt(this.corsi[xx].id) == parseInt(corso.id)) {

                    desc_azi_sel = this.corsi[xx].full_name;
                }


            }

            window.$cookies.set("sel_azi_name", desc_azi_sel, "9y");

            window.NomeAziendaSel = desc_azi_sel;

            this.setDomande(corso.id);


        },

        manageClickDomande: async function (domanda) {

            var that = this;

            console.log("DOMANDA SEL: ", domanda);

            that.sheetDomande = false;

            window.curDomanda = domanda.id;

            window.$cookies.set("sel_filter_domanda", domanda.id, "9y");

            var desc_domanda_sel = "";
            var tipologia_domanda_sel = "";

            for (var xx2 = 0; xx2 <= that.domande.length - 1; xx2++) {


                if (parseInt(that.domande[xx2].id) == parseInt(domanda.id)) {

                    desc_domanda_sel = that.domande[xx2].descrizione;
                    tipologia_domanda_sel = that.domande[xx2].tipologia;

                }


            }

            window.$cookies.set("sel_domanda_name", desc_domanda_sel, "9y");
            window.$cookies.set("sel_domanda_tipologia", tipologia_domanda_sel, "9y");


            window.DescDomandaSel = desc_domanda_sel;

            window.TipologiaDomandaSel = tipologia_domanda_sel;


            $("#NomeCorso").html(window.NomeAziendaSel);


            $("#DescDomanda").html(desc_domanda_sel);

            $("#TipoDomanda").html("Tipologia: " + tipologia_domanda_sel);

            setTimeout(() => {

                that.hideShowButtons(false);

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                window.table.ajax.reload();

            }, 200);




        },



        hideShowButtonsSelRow: function () {

            var tot = 0;
            var totPadre = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    var ispadre = $(this).attr("is-padre");



                    if (ispadre == 1) {

                        totPadre = totPadre + 1;
                    }


                }

            });

            console.log("TOT SEL: ", tot);

            var that = this;

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push(

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Aggiungi"


                        }

                    );

                }

                if (tot == 1) {


                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Modifica"
                    });


                }

                if (tot > 0) {


                    pulsantis.push(

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Elimina"


                        }

                    );

                }






                that.$root.$children[0].bnavbuttons = pulsantis;






                setTimeout(() => {


                    $('#btn_down_pdf').prop('disabled', false)
                    $('#btn_down_pdf img').css("opacity", "1");



                    if (that.isReadOnly == 1) {


                        $('#btn_add').prop('disabled', true)
                        $('#btn_add img').css("opacity", "0.5");

                        $('#btn_edit').prop('disabled', true)
                        $('#btn_edit img').css("opacity", "0.5");

                        $('#btn_assoc_moduli').prop('disabled', true)
                        $('#btn_assoc_moduli img').css("opacity", "0.5");

                        $('#btn_assoc').prop('disabled', true)
                        $('#btn_assoc img').css("opacity", "0.5");

                        $('#btn_upload_pdf').prop('disabled', true)
                        $('#btn_upload_pdf img').css("opacity", "0.5");

                        $('#btn_upload_doc').prop('disabled', true)
                        $('#btn_upload_doc img').css("opacity", "0.5");

                        $('#btn_delete').prop('disabled', true)
                        $('#btn_delete img').css("opacity", "0.5");

                    }



                }, 200);









            }, 100);



        },



        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;


            // var filtri = `<img src='/smartform/img/corsi_online_smartform.png' style='border:0!important;' class='selcorso' title='Corsi' /><img src='/smartform/img/corsi_online_domande_smartform.png' style='border:0!important;' class='seldomanda' title='Domande' />`;

            var filtri = ``;



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                var v_company = window.$cookies.get("sel_filter_corsi");
                var v_domanda = window.$cookies.get("sel_filter_domanda");

                window.curCompany = v_company;
                window.curStatus = "S"; // in forza
                window.curDomanda = v_domanda;

                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: 'sel_row',
                    width: '1%',
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },


                {
                    data: 'descrizione',
                    orderable: false,
                    name: 'descrizione',
                    width: '6%'
                },

                {
                    data: 'corretta',
                    orderable: false,
                    name: 'corretta',
                    width: '1%'
                },

                {
                    data: 'peso',
                    orderable: false,
                    name: 'peso',
                    width: '1%'
                },

                ];


                $(".spin").show();



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomainGo("getPesiRisposteCorsi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.curSrcText = window.curSrcText;

                            valori.curAnno = window.curAnno;

                            valori.curDomanda = window.curDomanda;

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);






                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {


                            pointerVue.$root.$children[0].showProgress = false;


                        }, 700);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomainGo("getPesiRisposteCorsi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div style='display:table'>
                        <div style='display:table-row'>
                            <div style='display:table-cell;float:left'>

                                <div class='cont-title-filters'>

                                    <div class="text_title">Configurazione Pesi Risposte</div>
                    ` + filtri + `

                                </div>

                            </div>
                        </div>


                        <div style='display:table-row'>
                            <div style='display:table-cell'>
                                <div style='display:flex'>
                                    <div class="nome_corso_label">Corso&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</div>
                                    <div class="nome_corso" id="NomeCorso">` + window.NomeCorsoSel + `</div>
                                </div>
                            </div>
                        </div>
                        <div style='display:table-row'>
                            <div style='display:table-cell'>

                                <div style='display:flex'>
                                    <div class="desc_domanda_label">Domanda:</div>
                                    <div class="desc_domanda" id="DescDomanda">` + window.DescDomandaSel + `</div>
                                </div>

                            </div>
                        </div>


                       
                    </div>

                 
                </div>
                

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });



                $('body').on('click', '.selpartecipanti', function () {


                    var ids = "";

                    $(".val_status").each(function (index, val) {

                        if ($(val).prop("checked")) {

                            var id = $(val).attr("data-id");

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + id;

                        }

                    });


                    console.log("IDS: ", ids);


                    if (ids != "") {

                        console.log("ok");

                        pointerVue.initPartecipanti(ids);

                    }
                    else {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Selezionare uno o più dipendenti/collaboratori",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                });







                $('body').on('click', '.cont_corsi_online_pesi_risposte_smartform tr', function () {


                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }



                });



                window.parentiAddedEvents = true;

            });

        },

        initPartecipanti: async function (list_id) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apirisorse.initPartecipanti(
                list_id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initPartecipanti", res);

                router.push({
                    path: "/partecipanti"
                });

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30,
                        title: "Aggiungi"

                    });

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/filter_corsi_online_smartform.png"),
                        link: "/settings",
                        id: "btn_filter_corsi",
                        disabled: false,
                        width: 35,
                        title: "Corsi"

                    });

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/filter_corsi_online_domande_smartform.png"),
                        link: "/settings",
                        id: "btn_filter_domande",
                        disabled: false,
                        width: 35,
                        title: "Domande"

                    });

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                            //class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Aggiungi"

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                            //class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            width: 30,
                            title: "Modifica"

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Elimina"

                        },




                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;




                setTimeout(() => {



                    if (this.isReadOnly == 1) {


                        $('#btn_add').prop('disabled', true)
                        $('#btn_add img').css("opacity", "0.5");

                    }



                }, 200);



            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/add_smartform.png",
                        // class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30,
                        title: "Aggiungi"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_smartform.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Modifica"

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete_smartform.png",
                        //class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30,
                        title: "Elimina"

                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },



        deleteRelazione: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare il file pdf ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {


                        that.$modal.hide('popupUploadPdf');




                        var id_azienda = window.$cookies.get("sel_filter_corsi");

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.deleteFilePdf(
                            ids,
                            id_azienda
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteFilePdf", res);

                            that.$swal({
                                icon: "success",
                                text: "Pdf eliminato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },





        deleteDocumento: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare il file del documento ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {


                        that.$modal.hide('popupUploadDoc');




                        var id_azienda = window.$cookies.get("sel_filter_corsi");

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.deleteFileDocumento(
                            ids,
                            id_azienda
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteFileDocumento", res);

                            that.$swal({
                                icon: "success",
                                text: "Documento eliminato correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },






        deleteRow: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.deletePesiRisposte(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deletePesiRisposte", res);

                            if (res.data.Result == "OK") {

                                that.$swal({
                                    icon: "success",
                                    text: "Dati eliminati correttamente",
                                    showConfirmButton: false,
                                    timer: 2000
                                });

                                setTimeout(() => {

                                    that.dialogMsg = false;
                                    that.setupButtons();

                                    that.hideShowButtons(false);

                                    window.table.ajax.reload();

                                }, 200);


                            }
                            else {

                                that.$swal({
                                    icon: "error",
                                    text: res.data.ErrDetails,
                                    showConfirmButton: false,
                                    timer: 3000
                                });


                            }



                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont_corsi_online_pesi_risposte_smartform .desc_domanda {
    color: black;
    font-weight: bold;
    margin-left: 0px;
    margin-top: 5px;
}


.cont_corsi_online_pesi_risposte_smartform .desc_domanda_label {
    color: black;
    font-weight: bold;
    margin-left: 0px;
    margin-top: 5px;
    width: 70px
}



.cont_corsi_online_pesi_risposte_smartform .nome_corso_label {
    color: black;
    font-weight: bold;
    margin-left: 0px;
    margin-top: 5px;
    width: 70px
}



.cont-title-filters {
    display: flex;
    justify-content: center;
    align-items: center;
}


.cont_corsi_online_pesi_risposte_smartform .selcorso {
    max-width: 30px;
    min-height: 30px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 30px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_corsi_online_pesi_risposte_smartform .seldomanda {
    max-width: 30px;
    min-height: 30px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 30px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}



.rowPrevFile .dropify-wrapper {
    height: 187px !important;
    width: 363px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}




.contpopupmoduligiornate .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}

.contpopupmoduligiornate .cl-sec-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}

.contpopupmoduligiornate .v-input.cl-checkbox.theme--light.v-input--selection-controls.v-input--checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}



.contSecCheck {
    align-items: top;
}



.cont_corsi_online_pesi_risposte_smartform .dataTable tbody tr,
.cont_corsi_online_pesi_risposte_smartform .dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
    /* padding-bottom: 4px !important; */
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #00a6ff;

}

.pay a {
    color: #00a6ff;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #00a6ff;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_corsi_online_pesi_risposte_smartform .val_status {
    width: 10px !important;
}


.cont_corsi_online_pesi_risposte_smartform #myTable {
    margin-right: 3px;
    width: 685px !important;
}


.cont_corsi_online_pesi_risposte_smartform table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_corsi_online_pesi_risposte_smartform table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    /* padding-top: 5px !important;
    vertical-align: middle; */
}

.cont_corsi_online_pesi_risposte_smartform th {
    padding-left: 5px !important;
}

.cont_corsi_online_pesi_risposte_smartform ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_corsi_online_pesi_risposte_smartform .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_corsi_online_pesi_risposte_smartform #myTable_filter {
    top: 66px;
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #0084f3;
}

.cont_corsi_online_pesi_risposte_smartform div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_corsi_online_pesi_risposte_smartform #myTable_wrapper {
    min-width: initial;
    min-width: 700px;
    max-width: 700px;
    width: 700px;
}

.cont_corsi_online_pesi_risposte_smartform .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_corsi_online_pesi_risposte_smartform .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_corsi_online_pesi_risposte_smartform .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/Filtro.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}

.cont_corsi_online_pesi_risposte_smartform .nome_azienda {
    color: black;
    font-weight: bold;
    margin-left: 0px;
}

.cont_corsi_online_pesi_risposte_smartform .nome_corso {
    color: black;
    font-weight: bold;
    margin-left: 0px;
    margin-top: 5px;
}



.cont_corsi_online_pesi_risposte_smartform .nome_domanda {
    color: black;
    font-weight: bold;
    margin-left: 0px;
}

.cont_corsi_online_pesi_risposte_smartform .nome_anno {
    color: black;
    font-weight: bold;
    margin-left: 35px;
}

.cont_corsi_online_pesi_risposte_smartform .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}

.cont_corsi_online_pesi_risposte_smartform .cl_checkall {
    margin-left: -14px
}


.cont_corsi_online_pesi_risposte_smartform .cl_checkall {
    padding-left: 15px;
    padding-top: 2px;
}

.cont_corsi_online_pesi_risposte_smartform .checkall {
    width: 13px;
    height: 13px;
}


.cont_corsi_online_pesi_risposte_smartform .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_corsi_online_pesi_risposte_smartform .selpartecipanti {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_corsi_online_pesi_risposte_smartform .selanno {
    max-width: 30px;
    min-height: 30px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 30px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.cont_corsi_online_pesi_risposte_smartform .selazienda {
    max-width: 30px;
    min-height: 30px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 30px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}


@media screen and (max-width: 960px) {

    .cont_corsi_online_pesi_risposte_smartform .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_corsi_online_pesi_risposte_smartform #myTable {
        /* margin-top:54px; */
    }

    .cont_corsi_online_pesi_risposte_smartform #myTable_filter {
        top: 56px;
        position: fixed;

    }



}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_corsi_online_pesi_risposte_smartform #myTable {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_corsi_online_pesi_risposte_smartform #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_corsi_online_pesi_risposte_smartform .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_corsi_online_pesi_risposte_smartform #myTable_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_corsi_online_pesi_risposte_smartform .cl_num {
        margin-left: -3px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cont_corsi_online_pesi_risposte_smartform .cl_checkall {
        margin-left: -14px
    }


}
</style>
