import axios from 'axios'

function getUrlDomainGeneral(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


}

var main_domain = getUrlDomainGeneral("");

class APICorsi {

    getUrlDomainGo(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = "http://localhost:4013/";
        }

        if (location.hostname != "localhost") {

            result = "https://filestransfer.it:4013/";

        }


        return result + nome_metodo;

    }


    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async insertCorso(corso, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomain("SFCorsiInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async editCorso(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);


        return axios.post(this.getUrlDomain("SFCorsiEditApi"), formData, { 'Content-Type': 'multipart/form-data' });


    }

    async updateCorso(corso, id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomain("SFCorsiUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteCorso(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFCorsiDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getFilters(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("SFGetFiltersModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getNomiByCodice(id_codice, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id_codice", id_codice);

        return axios.post(this.getUrlDomain("SFGetNomiByCodiceModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getTotalHour(id_codice, nome, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id_codice", id_codice);
        formData.append("nome", nome);

        return axios.post(this.getUrlDomain("SFGetTotHoursModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async reportPdf(id, id_azi) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("v_id_azi", id_azi);

        return axios.post(this.getUrlDomain("SFCorsiPdfReportApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async assocGiorniGetDate(id) {

        let formData = new FormData();

        formData.append("id", id);

        return axios.post(this.getUrlDomain("SFCorsiAssocGiorniGetDateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async assocGiorniGetArgs(id_data, id_corso) {

        let formData = new FormData();

        formData.append("v_id_data", id_data);
        formData.append("v_id", id_corso);

        return axios.post(this.getUrlDomain("SFCorsiAssocGiorniGetArgsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async assocGiorniSave(v_id_data, v_codice_argomento, v_nome_argomento, v_ids, v_txt_data, v_tot_h_args, v_tot_min_args, v_id_corso) {

        let formData = new FormData();

        formData.append("v_id_data", v_id_data);
        formData.append("v_codice_argomento", v_codice_argomento);
        formData.append("v_nome_argomento", v_nome_argomento);
        formData.append("v_ids", v_ids);
        formData.append("v_txt_data", v_txt_data);
        formData.append("v_tot_h_args", v_tot_h_args);
        formData.append("v_tot_min_args", v_tot_min_args);
        formData.append("v_id_corso", v_id_corso);


        return axios.post(this.getUrlDomain("SFCorsiAssocGiorniSaveApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getDataCorsiSel(ids) {

        let formData = new FormData();

        formData.append("ids", ids);

        return axios.post(this.getUrlDomain("SFCorsiGetDataCorsoMasterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async insertCorsoCappello(corso, user, ids) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("ids", ids);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomain("SFCorsiInsertCorsoMasterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async editCorsoCappello(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);


        return axios.post(this.getUrlDomain("SFCorsiEditCorsoMasterApi"), formData, { 'Content-Type': 'multipart/form-data' });


    }

    async updateCorsoCappello(corso, id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomain("SFCorsiUpdateCorsoMasterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async getModuli(user, ids) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("ids", ids);

        return axios.post(this.getUrlDomain("SFCorsiGetModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async setModulo(ids, idmodsel) {

        let formData = new FormData();

        formData.append("ids", ids);
        formData.append("idmodsel", idmodsel);

        return axios.post(this.getUrlDomain("SFCorsiAssocModuliApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async uploadAllegatoPartAssign(user, idcorso, idazienda, idpart, file, v_anno, v_mese) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idcorso", idcorso);
        formData.append("idazienda", idazienda);
        formData.append("idpart", idpart);
        formData.append("file", file);
        formData.append("v_anno", v_anno);
        formData.append("v_mese", v_mese);

        return axios.post(this.getUrlDomain("SFCorsiUplAllagetaPartAssocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async uploadAllegatoCorsoRelazione(user, idazienda, idcorso, file) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idcorso", idcorso);
        formData.append("idazienda", idazienda);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("SFCorsiUplRelCorsoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async uploadAllegatoCVPartAssign(user, idcorso, idazienda, idpart, file) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idcorso", idcorso);
        formData.append("idazienda", idazienda);
        formData.append("idpart", idpart);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("SFCorsiUplCVPartAssocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async uploadAllegatoCorsoDocumento(user, idazienda, idcorso, file) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idcorso", idcorso);
        formData.append("idazienda", idazienda);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("SFCorsiUplDocCorsoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async setAziendeForUtente(idutente, ids) {

        let formData = new FormData();

        formData.append("idutente", idutente);
        formData.append("ids", ids);


        return axios.post(this.getUrlDomain("SFSetAziendeForUtentiApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFileRelazione(id, idazienda) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("v_idazienda", idazienda);

        return axios.post(this.getUrlDomain("SFDelFileRelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFileDocumento(id, idazienda) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("v_idazienda", idazienda);

        return axios.post(this.getUrlDomain("SFDelFileDocApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteZipLul(id, idcorso, v_anno, v_mese) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("idcorso", idcorso);
        formData.append("v_anno", v_anno);
        formData.append("v_mese", v_mese);

        return axios.post(this.getUrlDomain("SFDelZipLulApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteFileCV(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFDelCVPartApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getInfoPartecipanti(id, idcorso, v_anno, v_mese) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("idcorso", idcorso);
        formData.append("v_anno", v_anno);
        formData.append("v_mese", v_mese);

        return axios.post(this.getUrlDomain("SFGetInfoPartApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateOre(idpart, oretotali, idcorso, anno, mese) {

        let formData = new FormData();

        formData.append("idpart", idpart);
        formData.append("oretotali", oretotali);
        formData.append("idcorso", idcorso);
        formData.append("anno", anno);
        formData.append("mese", mese);

        return axios.post(this.getUrlDomain("SFUpdOreLULApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async uploadCostoAziendale(user, idazienda, idpart, file) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idazienda", idazienda);
        formData.append("idpart", idpart);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("SFUplCostoAziApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async deletePdf(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFDelPdfApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async refreshHourPeriod(idazienda, idcorso, anno, mese) {

        let formData = new FormData();

        formData.append("idazienda", idazienda);
        formData.append("idcorso", idcorso);
        formData.append("anno", anno);
        formData.append("mese", mese);

        return axios.post(this.getUrlDomain("SFRefreshOreLULApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async downloadPdfNew(v_path) {

        let formData = new FormData();

        formData.append("f", v_path);


        return axios.post(this.getUrlDomain("TwoRecruitDownloadPdfApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async setPosModuloAssoc(idcorso, v_pos, v_id) {

        let formData = new FormData();

        formData.append("idcorso", idcorso);
        formData.append("v_pos", v_pos);
        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("SFCorsiAssocModuliPosApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async insertCorsoOnline(corso, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomain("SFCorsiOnlineInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertCorsoOnlineGo(corso, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomainGo("insertCorsoOnline"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async editCorsoOnline(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);


        return axios.post(this.getUrlDomain("SFCorsiOnlineEditApi"), formData, { 'Content-Type': 'multipart/form-data' });


    }

    async editCorsoOnlineGo(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);


        return axios.post(this.getUrlDomainGo("editCorsoOnline"), formData, { 'Content-Type': 'multipart/form-data' });


    }


    async updateCorsoOnline(corso, id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomain("SFCorsiOnlineUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateCorsoOnlineGo(corso, id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);

        for (var key in corso) {
            formData.append(key, corso[key]);
        }

        return axios.post(this.getUrlDomainGo("updateCorsoOnline"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteCorsoOnline(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("SFCorsiOnlineDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteCorsoOnlineGo(id) {

        let formData = new FormData();

        formData.append("v_id", id);

        return axios.post(this.getUrlDomainGo("deleteCorsoOnline"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async uploadAllegatoCorsoOnline(user, idazienda, idcorso, file) {

        let formData = new FormData();

        formData.append("user", user);
        formData.append("idcorso", idcorso);
        formData.append("idazienda", idazienda);
        formData.append("file", file);

        return axios.post(this.getUrlDomain("SFCorsiUplPdfCorsoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async deleteFilePdf(id, idazienda) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("v_idazienda", idazienda);

        return axios.post(this.getUrlDomain("SFDelFileOnlinePdfApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getCorsiOnline(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("SFGetCorsiOnlineDDLApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getCorsiOnlineGo(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomainGo("getCorsiOnlineDDL"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initInsertStepFlusso(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("SFInitInsStepFlussoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async insertStepFlusso(step_flusso) {

        let formData = new FormData();

        for (var key in step_flusso) {
            formData.append(key, step_flusso[key]);
        }

        return axios.post(this.getUrlDomain("SFInsertStepFlussoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async editStepFlusso(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("SFEditStepFlussoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateStepFlusso(step_flusso, v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        for (var key in step_flusso) {
            formData.append(key, step_flusso[key]);
        }

        return axios.post(this.getUrlDomain("SFUpdateStepFlussoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteStepFlusso(v_id, id_corso) {

        let formData = new FormData();

        formData.append("v_id", v_id);
        formData.append("id_corso", id_corso);

        return axios.post(this.getUrlDomain("SFDeleteStepFlussoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getInfoCorso(v_token) {

        let formData = new FormData();

        formData.append("v_token", v_token);

        return axios.post(this.getUrlDomain("SFGetInfoCorsoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async goNextStep(v_token) {

        let formData = new FormData();

        formData.append("v_token", v_token);

        return axios.post(this.getUrlDomain("SFNextStepApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async verifyStep(v_token) {

        let formData = new FormData();

        formData.append("v_token", v_token);

        return axios.post(this.getUrlDomain("SFVerifyStepApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async initInsertDomanda(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("SFInitInsDomandaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertDomanda(domanda) {

        let formData = new FormData();

        for (var key in domanda) {
            formData.append(key, domanda[key]);
        }

        return axios.post(this.getUrlDomain("SFInsertDomandaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async editDomanda(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("SFEditDomandaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateDomanda(domanda, v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        for (var key in domanda) {
            formData.append(key, domanda[key]);
        }

        return axios.post(this.getUrlDomain("SFUpdateDomandaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteDomande(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("SFDeleteDomandaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getCorsiOnlineDomande(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("SFGetDomandeCorsiOnlineDDLApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getCorsiOnlineDomandeGo(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomainGo("getDomandeCorsiOnline"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertRisposta(risposta) {

        let formData = new FormData();

        for (var key in risposta) {
            formData.append(key, risposta[key]);
        }

        return axios.post(this.getUrlDomain("SFInsertRispostaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async editRisposta(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("SFEditRispostaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateRisposta(risposta, v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        for (var key in risposta) {
            formData.append(key, risposta[key]);
        }

        return axios.post(this.getUrlDomain("SFUpdateRispostaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteRisposte(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("SFDeleteRisposteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initInsertPesoRisposta(id_domanda) {

        let formData = new FormData();

        formData.append("id_domanda", id_domanda);

        return axios.post(this.getUrlDomain("SFRispDDLPesoApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertPesoRisposta(peso_risposta) {

        let formData = new FormData();

        for (var key in peso_risposta) {
            formData.append(key, peso_risposta[key]);
        }

        return axios.post(this.getUrlDomain("SFInsertPesoRispostaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async editPesoRisposta(id_domanda, v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);
        formData.append("id_domanda", id_domanda);

        return axios.post(this.getUrlDomain("SFEditPesoRispostaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updatePesoRisposta(peso_risposta, v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        for (var key in peso_risposta) {
            formData.append(key, peso_risposta[key]);
        }

        return axios.post(this.getUrlDomain("SFUpdatePesoRispostaApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deletePesiRisposte(v_id) {

        let formData = new FormData();

        formData.append("v_id", v_id);

        return axios.post(this.getUrlDomain("SFDeletePesiRisposteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getDomandeRisposte(v_token) {

        let formData = new FormData();

        formData.append("v_token", v_token);

        return axios.post(this.getUrlDomain("SFGetDomandeRisposteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async validateDomandeRisposte(v_token, risposte) {

        let formData = new FormData();

        formData.append("v_token", v_token);
        formData.append("risposte", risposte);

        return axios.post(this.getUrlDomain("SFValidateDomandeRisposteApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async reopenCourse(v_id, v_token) {

        let formData = new FormData();

        formData.append("v_id", v_id);
        formData.append("v_token", v_token);

        return axios.post(this.getUrlDomain("SFReopenCourse"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async sendMail(v_token, v_res_corso, v_num_domande, v_num_risp_ok) {

        let formData = new FormData();

        formData.append("v_token", v_token);
        formData.append("v_res_corso", v_res_corso);
        formData.append("v_num_domande", v_num_domande);
        formData.append("v_num_risp_ok", v_num_risp_ok);

        return axios.post(this.getUrlDomain("SFSendMail"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async createDocToSign(v_token) {

        let formData = new FormData();

        formData.append("v_token", v_token);

        return axios.post(this.getUrlDomain("createDocToSign"), formData, { 'Content-Type': 'multipart/form-data' });

    }


}

export default new APICorsi({
    url: main_domain
})
